export interface ApiError {
  code?: string
  description?: string;
}

export interface ApiBaseResponse {
  succeeded: boolean;
  statusCodeInt: number;
  statusCode: string;
}

export interface ApiErrorResponse extends ApiBaseResponse {
  error: ApiError
}

export interface ApiResponse<T> extends ApiBaseResponse {
  data: T;
}

export function isApiBaseResponse(obj: any): obj is ApiBaseResponse {
  return 'succeeded' in obj && 'statusCode' in obj
}

export function isApiErrorResponse(obj: any): obj is ApiErrorResponse {
  return isApiBaseResponse(obj) && 'error' in obj
}
