import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

export const setLanguageInterceptor: HttpInterceptorFn = (req, next) => {
	const translateService = inject(TranslocoService);

	const languageRequest = req.clone({
		headers: req.headers.set('Accept-Language', translateService.getActiveLang())
	});

	return next(languageRequest);
};
