import { Injectable } from '@angular/core';
import { IAlertService } from '../../../core/services/notifications/alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorMessageComponent, ErrorMessageData } from '../../components/error-message/error-message.component';

@Injectable()
export class AlertService extends IAlertService {

	constructor(private readonly _snackbar: MatSnackBar) {
		super();
	}

	override alertError(title: string, message?: string, duration?: number) {
		this._snackbar.openFromComponent<ErrorMessageComponent, ErrorMessageData>(ErrorMessageComponent, {
			data: {
				title,
				message
			},
			duration,
			verticalPosition: 'top'
		});
	};
}
