import { ApplicationConfig, provideZoneChangeDetection, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { handleErrorsInterceptor } from './shared/interceptors/handle-errors.interceptor';
import { setLanguageInterceptor } from './shared/interceptors/set-language.interceptor';
import { IConfigurationService } from './core/services/configuration/configuration.service';
import { ConfigurationService } from './shared/services/configuration/configuration.service';
import { IAlertService } from './core/services/notifications/alert.service';
import { AlertService } from './shared/services/notifications/alert.service';
import { AbstractSecurityStorage, authInterceptor, provideAuth, StsConfigLoader } from 'angular-auth-oidc-client';
import { openIdConfigurationFactory } from './shared/auth/openid-configuration';
import { OpenIdLocalStorage } from './shared/auth/openid-storage';
import { availableLangs, defaultLang } from './features/i18n/models/i18n.models';

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptors([handleErrorsInterceptor, setLanguageInterceptor, authInterceptor()])),
		provideTransloco({
			config: {
				availableLangs: availableLangs.map(i => i.code),
				defaultLang: defaultLang.code,
				reRenderOnLangChange: true,
				prodMode: !isDevMode(),
			},
			loader: TranslocoHttpLoader
		}),
		{
			provide: IConfigurationService,
			useClass: ConfigurationService
		},
		{
			provide: IAlertService,
			useClass: AlertService
		},
		provideAuth({
			loader: {
				provide: StsConfigLoader,
				useFactory: openIdConfigurationFactory,
			},
		}),
		{
			provide: AbstractSecurityStorage,
			useClass: OpenIdLocalStorage
		},
	]
};
