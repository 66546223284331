import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, take } from 'rxjs';

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
	const oidcSecurityService = inject(OidcSecurityService);
	const router = inject(Router);

	return oidcSecurityService.isAuthenticated$.pipe(
		take(1),
		map((v) => {
			if (!v.isAuthenticated) {
				//router.navigate(['']);
				oidcSecurityService.authorize();
				return false;
			}
			return true;
		})
	);
};
