export interface Language {
	code: string;
	englishName: string;
	nativeName: string;
}

export const availableLangs: Language[] = [{
	code: "en",
	englishName: "English",
	nativeName: "English"
}, {
	code: "ru",
	englishName: "Russian",
	nativeName: "Русский"
}];
export const defaultLang: Language = availableLangs.find(i => i.code === "en")!;
