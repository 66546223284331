import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { IAlertService } from '../../core/services/notifications/alert.service';
import { catchError, throwError } from 'rxjs';
import { isApiErrorResponse } from '../../core/models/api/api.models';

export const handleErrorsInterceptor: HttpInterceptorFn = (req, next) => {
	const alertService = inject(IAlertService);
	return next(req)
		.pipe(
			catchError((e: HttpErrorResponse) => {
				let title = "Ошибка";
				if (e.error && isApiErrorResponse(e.error)) {
					alertService.alertError(title, e.error.error.description, 5000);
				}
				else {
					alertService.alertError(title, e.message, 5000);
				}
				return throwError(() => e);
			})
		);
};
