import { Observable } from "rxjs";
import { Configuration } from "../../models/configuration/configuration.models";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export abstract class IConfigurationService {
	abstract getConfiguration(): Observable<Configuration>;
}
