<button mat-button [matMenuTriggerFor]="menu" (menuOpened)="onMenuStateChange(true)" (menuClosed)="onMenuStateChange(false)">
	{{ currentLanguage()?.nativeName }}
	<mat-icon>{{ dropdownIcon() }}</mat-icon>
</button>
<mat-menu #menu="matMenu">
	@for (lang of availableLanguages(); track $index) {
	<button mat-menu-item (click)="setLanguage(lang)">
		<span>{{ lang.nativeName }}</span>
	</button>
	}
</mat-menu>
